import React from "react";
import { CustomHTMLPropTypes } from "../../../../proptypes/custom-html";

const CustomHTML = ({ customFields }) => {
  const { rawHtml } = customFields;
  return <div dangerouslySetInnerHTML={{ __html: rawHtml }} />;
};

CustomHTML.propTypes = CustomHTMLPropTypes;
export default CustomHTML;
